import axios from 'axios';
import path from 'path';
import React, { useState, useEffect } from 'react';

import './ProcessVideo.css';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconDownload from '@material-ui/icons/GetApp';

const BUCKET_NAME = 'aiugc';
const URL_API_AI = 'https://ai.foundrysix.com/api/json/'

const ProcessVideo = (props) => {

    const [values, setValues] = useState({
        urlSourceImage: "",
        urlTargetVideo: "",
        onProcessVideo: null,
        isLoading: false,
    });

    const [statusProcessVideo, setStatusProcessVideo] = useState("");
    const [downloadLink, setDownloadLink] = useState("");
    const [isBlend, setIsBlend] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        setValues({
            ...values,
            urlSourceImage: props.urlSourceImage,
            urlTargetVideo: props.urlTargetVideo,
            onProcessVideo: props.onProcessVideo
        });
    }, [props]);


    const onSubmit = () => {
        console.log("[ProcessVideo] onSubmit urlSourceImage:", values.urlSourceImage, " urlTargetVideo:", values.urlTargetVideo);
        if (values.urlSourceImage !== "" && values.urlTargetVideo !== "") {
            setValues({
                ...values,
                isLoading: true,
            });
            processVideo(values.onProcessVideo);
        }
        else {
            console.log("[ProcessVideo] missing urlSourceImage, urlTargetVideo");
        }
    };


    // test callback
    const onProcessVideo = (url) =>  {
        console.log("[ProcessVideo] url:", url);
    };


    const processVideo = (onProcessVideo) => {
        console.log("[ProcessVideo] processVideo");
        setDownloadLink('');
        setStatusProcessVideo('');

        axios.get(URL_API_AI + "faceswap/video?image=" + values.urlSourceImage + "&video=" + values.urlTargetVideo)
        .then(response => {
            console.log("[ProcessVideo] response:", response);
            let data = response.data.data;
            setValues({
                ...values,
                isLoading: false
            });
            setDownloadLink(data.url);
            setStatusProcessVideo(data.url);
            onProcessVideo(data.url);
        })
        .catch(error => {
            console.log("[ProcessVideo] error:", error);
            setValues({
                ...values,
                isLoading: false
            });
            setDownloadLink('');
            setStatusProcessVideo("error")
        });
    };


    const download = (url) => {
        if (!url) {
          throw new Error("Resource URL not provided! You need to provide one");
        }

        setIsDownloading(true);

        fetch(url)
        .then(response => response.blob())
        .then(blob => {
          setIsDownloading(false);
          const blobURL = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobURL;
          link.style = "display: none";
          const parts = url.split("/");
          link.download = parts[parts.length - 1];
          document.body.appendChild(link);
          setTimeout(function() {
               link.click();
               document.body.removeChild(link);
           }, 500);
        })
        .catch(() => console.log("download error"));
    };


    const toggleBlend = () => {
        setIsBlend(!isBlend);
    };


    // render

    return (
        <div className="process-video">
            {values.isLoading ? (
                <div className="process-isLoading">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="process-field">Face swap image to video</div>
                    <div className="process-info">
                        <div>
                            <Checkbox
                                checked={isBlend}
                                color="primary"
                                onChange={() => {
                                    toggleBlend(isBlend);
                                }}
                                size="small"
                            /> blend
                        </div>
                        { downloadLink !== ""
                            ? <div>
                                <button
                                    disabled={isDownloading}
                                    onClick={()=> download(downloadLink)}
                                >
                                    <IconDownload/>
                                </button>
                            </div>
                            : null
                        }
                        <p className="process-link">{statusProcessVideo}</p>
                    </div>

                    <button onClick={onSubmit}>Face Swap</button>
                </>
            )}
        </div>
    );
};

export default ProcessVideo;
