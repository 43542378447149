// lib
import axios from 'axios';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, ListItem, ListItemText, Radio, TextField } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { FixedSizeList } from 'react-window';
import { Link as RouterLink } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

// icons
import IconAdd from '@material-ui/icons/Add';
import IconAddVideoToScene from '@material-ui/icons/AddToPhotos';
import IconArrowLeft from '@material-ui/icons/ArrowLeft';
import IconArrowRight from '@material-ui/icons/ArrowRight';
import IconCheck from '@material-ui/icons/Check';
import IconCheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import IconClear from '@material-ui/icons/Clear';
import IconPeople from '@material-ui/icons/People';
import IconRemove from '@material-ui/icons/Remove';
import IconRemoveCircle from '@material-ui/icons/RemoveCircle';
import IconThumbnail from '@material-ui/icons/Image';

// content
import "./CreateScene.css";
import FileUploadWithTitle from "./FileUploadWithTitle";
import ProcessVideo from "./ProcessVideo";
import ProcessScene from "./ProcessScene";
import ShareScene from "./ShareScene";

const URL_API_FACESWAP = 'https://56mrpvwgbl.execute-api.us-east-1.amazonaws.com/faceswap/';
const URL_API_AI = 'https://ai.foundrysix.com/api/json/';
const USERID = 'faceswap';
const SESSION = "20210624";

let contentTypeOut = '';
let sceneIdOut = '';

const CreateScene = () => {

    const [isShowConfirmRemoveScene, setIsShowConfirmRemoveScene] = useState(false);
    const [isShowConfirmRemoveVideo, setIsShowConfirmRemoveVideo] = useState(false);
    const [isShowConfirmRemoveVideoFromScene, setIsShowConfirmRemoveVideoFromScene] = useState(false);
    const [isShowCreateNewScene, setIsShowCreateNewScene] = useState(false);
    const [sceneId, setSceneId] = useState('');
    const [scenes, setScenes] = useState([]);
    const [selectedSceneIndex, setSelectedSceneIndex] = useState(-1);
    const [selectedVideoIndex, setSelectedVideoIndex] = useState(-1);
    const [selectedVideo, setSelectedVideo] = useState({});
    const [selectedVideoInScene, setSelectedVideoInScene] = useState({});
    const [thumbnailId, setThumbnailId] = useState('');
    const [contentType, setContentType] = useState('');
    const [videos, setVideos] = useState([]);
    const [sceneVideos, setSceneVideos] = useState([]);
    const [textFieldSceneId, setTextFieldSceneId] = useState('');
    const [urlPreviewScene, setUrlPreviewScene] = useState('');
    const [urlTargetVideo, setUrlTargetVideo] = useState('');
    const [urlSceneVideo, setUrlSceneVideo] = useState('');

    useEffect(() => {
        getScenes();
        getVideos();
    }, []);


    const getScenes = () => {
        console.log("[CreateScene] getScenes");
        axios.post(
            URL_API_FACESWAP + 'contents/get',
            { "appId": "faceswapscene" }
        )
        .then(response => {
            console.log("[CreateScene] getScenes response:", response);
            if (response.data.r === 's' && response.data.data) {
                setScenes(response.data.data);
            }
            console.log("[CreateScene] getScenes scenes:", scenes);
        })
        .catch(error => {
            console.log("[CreateScene] getScenes error:", error);
        });
    };


    const getVideos = () => {
        console.log("[CreateScene] getVideos");
        axios.post(
            URL_API_FACESWAP + "content/get/",
            { "contentId": "faceswapvideos" }
        )
        .then(response => {
            console.log("[CreateScene] getVideos response:", response);
            if (response.data.r === 's' && response.data.data && response.data.data.content) {
                setVideos(response.data.data.content);
                console.log("[CreateScene] getVideos videos:", response.data.data.content);
            }
        })
        .catch(error => {
            console.log("[CreateScene] getVideos error:", error);
        });
    };


    const pickScene = (sceneIdIn) => {
        console.log("[CreateScene] pickScene sceneId:", sceneIdIn);

        sceneIdOut = sceneIdIn;
        setSceneId(sceneIdIn);

        axios.get(URL_API_FACESWAP + "content/get/" + sceneIdIn)
        .then(response => {
            console.log("[CreateScene] pickScene response:", response);
            let data = response.data.data;
            if (data.content) {
                const videosCount = data.content.length;
                for (let i = 0; i < videosCount; i++) {
                    if (!data.content[i].hasOwnProperty('videoId')) {
                        data.content[i].videoId = getRandomString(10);
                    }
                }
                setSceneVideos(data.content);
                if (data.thumbnailId) {
                    setThumbnailId(data.thumbnailId);
                }
                contentTypeOut = data.contentType ? data.contentType : 'scene';
                setContentType(contentTypeOut);
            }
            console.log("[CreateScene] pickScene content:", data);
        })
        .catch(error => {
            console.log("[CreateScene] pickScene error:", error);
        });
    };


    const pickVideo = (videoIn) => {
        console.log("[CreateScene] pickVideo videoIn:", videoIn, 'sceneId:', sceneId);

        setSelectedVideo(videoIn);
    }


    const addSelectedVideoToScene = () => {

        console.log("[CreateScene] addSelectedVideoToScene selectedVideo:", selectedVideo);

        if (sceneId === '' || !selectedVideo.hasOwnProperty('url')) {
            return;
        }

        selectedVideo.videoId = getRandomString(10);

        sceneVideos.push(selectedVideo);

        setSceneVideos([...sceneVideos]);

        setSceneData(sceneId, JSON.stringify(sceneVideos));
    };


    const toggleSwappable = (video) => {
        video.isSwappable = !video.isSwappable;
        setSceneVideos([...sceneVideos]);
        console.log('[CreateScene] toggleSwappable video:', video, 'sceneVideos', sceneVideos);
        setSceneData(sceneId, JSON.stringify(sceneVideos));
    };


    const toggleThumbnail = async (video) => {
        //console.log('[CreateScene] toggleThumbnail video:', video);
        if (video.videoId) {
            setThumbnailId(video.videoId);
        }

        const resGetScene = await axios.get(URL_API_FACESWAP + "content/get/" + sceneIdOut);
        if (resGetScene.data.r !== 's' || !resGetScene.data.data) {
            return;
        }

        let sceneIn = resGetScene.data.data;

        // generate video thumbnail

        const resVideoThumbnail = await axios.get(URL_API_AI + "video/thumbnail?urlVideo=" + video.url);
        if (resVideoThumbnail.data.r !== 's' || !resVideoThumbnail.data.data) {
            return;
        }

        sceneIn.thumbnail = resVideoThumbnail.data.data.url;
        sceneIn.thumbnailId = video.videoId;
        sceneIn.content = JSON.stringify(sceneIn.content);

        if (!sceneIn.contentType) {
            sceneIn.contentType = contentTypeOut;
        }

        //console.log("[CreateScene] toggleThumbnail resVideoThumbnail:", resVideoThumbnail, "sceneIn:", sceneIn)

        const resAddScene = await axios.post(
            URL_API_FACESWAP + "content/add",
            sceneIn
        );

        console.log("[CreateScene] toggleThumbnail resAddScene:", resAddScene);
    };


    const updateContentType = async () => {

        console.log("[CreateScene] updateContentType contentTypeOut:", contentTypeOut, "sceneId:", sceneIdOut);

        const resGetScene = await axios.get(URL_API_FACESWAP + "content/get/" + sceneIdOut);

        console.log("[CreateScene] updateContentType resGetScene:", resGetScene)

        if (resGetScene.data.r !== 's' || !resGetScene.data.data) {
            return;
        }

        let sceneIn = resGetScene.data.data;

        sceneIn.content = JSON.stringify(sceneIn.content);

        sceneIn.contentType = contentTypeOut;

        const resAddScene = await axios.post(
            URL_API_FACESWAP + "content/add",
            sceneIn
        );

        console.log("[CreateScene] updateContentType resAddScene:", resAddScene);
    };


    const debounceUpdateContentType = useCallback(debounce(updateContentType, 500), []);


    const onChangeContentType = (contentTypeIn) => {
        console.log('[CreateScene] onChangeContentType contentTypeIn:', contentTypeIn);

        setContentType(contentTypeIn);

        contentTypeOut = contentTypeIn;

        debounceUpdateContentType();
    }


    const setSceneData = (sceneIdIn, sceneDataIn) => {
        const dataContent = { contentId: sceneIdIn, appId: "faceswapscene", type: "scene", content: sceneDataIn, contentType: contentTypeOut };
        console.log("[CreateScene] setSceneData contentId:", sceneIdIn, "content:", sceneDataIn);

        const headers = {
            'Content-Type': 'application/json',
        };
        console.log("[CreateScene] setSceneData:", headers);
        axios.post(
            URL_API_FACESWAP + "content/add",
            dataContent,
            { headers: headers }
        )
        .then(response => {
            console.log("[CreateScene] setSceneData response:", response);
        })
        .catch(error => {
            console.log("[CreateScene] setSceneData error:", error);
        });
    };


    const setVideosData = (videosDataIn) => {
        const dataContent = { contentId: "faceswapvideos", appId: "faceswapvideo", type: "videos", content: videosDataIn };
        console.log("[CreateScene] setSceneData contentId:", "faceswapvideos", "content:", videosDataIn);

        const headers = {
            'Content-Type': 'application/json',
        };
        console.log("[CreateScene] setVideosData:", headers);
        axios.post(
            URL_API_FACESWAP + "content/add",
            dataContent,
            { headers: headers }
        )
        .then(response => {
            console.log("[CreateScene] setVideosData response:", response);
        })
        .catch(error => {
            console.log("[CreateScene] setVideosData error:", error);
        });
    };


    // remove scene

    const showConfirmRemoveScene = () => {
        console.log("[CreateScene] showConfirmRemoveScene");
        setIsShowConfirmRemoveScene(true);
    }


    const closeConfirmRemoveScene = () => {
        console.log("[CreateScene] closeConfirmRemoveScene");
        setIsShowConfirmRemoveScene(false);
    }


    const removeScene = async () => {
        console.log("[CreateScene] removeScene sceneId:", sceneIdOut);

        setIsShowConfirmRemoveScene(false);

        axios.post(URL_API_FACESWAP + "content/remove", { "contentId": sceneIdOut })
        .then(async (response) => {
            console.log("[CreateScene] removeScene response:", response);
            setSceneId('');
            setSelectedSceneIndex(-1);
            getScenes();
        })
        .catch(error => {
            console.log("[CreateScene] removeScene error:", error);
        });
    };


    // remove video

    const showConfirmRemoveVideo = () => {
        console.log("[CreateScene] showConfirmRemoveVideo");
        setIsShowConfirmRemoveVideo(true);
    }


    const closeConfirmRemoveVideo = () => {
        console.log("[CreateScene] closeConfirmRemoveVideo");
        setIsShowConfirmRemoveVideo(false);
    }


    const removeVideo = (videoIn) => {
        console.log("[CreateScene] removeVideo video:", selectedVideo);

        setIsShowConfirmRemoveVideo(false);

        let videosNew = videos.filter(video => { return video.url !== selectedVideo.url });

        axios.post(
            URL_API_FACESWAP + "content/add",
            {
                "contentId": "faceswapvideos",
                "appId": "faceswapvideos",
                "content": JSON.stringify(videosNew),
                "type": "videos"
            }
        )
        .then(async (response) => {
            console.log("[CreateScene] removeVideo response:", response);
            setSelectedVideoIndex(-1);
            getVideos();
        })
        .catch(error => {
            console.log("[CreateScene] removeVideo error:", error);
        });
    };


    const renderScenesList = (props) => {
        const { index, style } = props;

        return (
            <ListItem
                button
                style={style}
                key={`scene-${index}`}
                onClick={() => {
                    setSelectedSceneIndex(index);
                    pickScene(scenes[index].contentId);
                    console.log('[CreateScene] renderScenesList onClick index:', index, 'selectedSceneIndex:', selectedSceneIndex);
                }}
                selected={selectedSceneIndex === index}
            >
                <ListItemText
                    primary={`${scenes[index].contentId}`}
                />
            </ListItem>
        );
    }


    const renderVideosList = (props) => {
        const { index, style } = props;

        return (
            <ListItem
                button
                style={style}
                key={`video-${index}`}
                onClick={() => {
                    setSelectedVideoIndex(index);
                    pickVideo(videos[index]);
                }}
                selected={selectedVideoIndex === index}
            >
                <ListItemText
                    primary={`${videos[index].title ? videos[index].title : videos[index].url}`}
                />
            </ListItem>
        );
    }


    const onUploadedVideo = async (url, title) => {
        // TODO: add url to content for appId: "videos", contentId: "faceswap"

        console.log('[CreateScene] onUploadedTarget title:', title, 'url:', url);
        let videosNew = JSON.parse(JSON.stringify(videos));
        videosNew.push({ title: title, url: url});

        setVideos([...videosNew]);

        axios.post(
            URL_API_FACESWAP + 'content/add',
            {
                "contentId": "faceswapvideos",
                "appId": "faceswapvideos",
                "content": JSON.stringify(videosNew),
                "type": "videos"
            }
        );

        setSelectedVideoIndex(-1);
    }


    // remove video from scene

    const showConfirmRemoveVideoFromScene = async (video) => {
        setSelectedVideoInScene(video);
        setIsShowConfirmRemoveVideoFromScene(true);
    }


    const removeVideoFromScene = async () => {
        setIsShowConfirmRemoveVideoFromScene(false);

        const sceneVideosCount = sceneVideos.length;
        let sceneVideosNew = [];
        for (let i = 0; i < sceneVideosCount; i++) {
            if (sceneVideos[i].videoId === selectedVideoInScene.videoId) {
                let sceneVideosFront = sceneVideos.slice(0, i);
                let sceneVideosBack = i == sceneVideosCount - 1 ? [] : sceneVideos.slice(i + 1);
                sceneVideosNew = sceneVideosFront.concat(sceneVideosBack);
                break;
            }
        }

        console.log("[CreateScene] removeVideoFromScene sceneVideosNew:", sceneVideosNew);

        setSceneVideos([...sceneVideosNew]);

        console.log("[CreateScene] removeVideoFromScene sceneId:", sceneIdOut);

        // update scene data
        setSceneData(sceneIdOut, JSON.stringify(sceneVideosNew));
    }


    const closeConfirmRemoveVideoFromScene = () => {
        setIsShowConfirmRemoveVideoFromScene(false);
    }


    const shiftLeftVideo = async (video) => {
        const sceneVideosCount = sceneVideos.length;
        let sceneVideosNew = [];
        for (let i = 0; i < sceneVideosCount; i++) {
            if (sceneVideos[i].videoId === video.videoId) {
                if (i === 0) {
                    break;
                }

                let sceneVideosFront = sceneVideos.slice(0, i - 1 > 0 ? i - 1 : 0);
                let sceneVideosMidFront = sceneVideos.slice(i, i + 1);
                let sceneVideosMidBack = sceneVideos.slice(i - 1, i);
                let sceneVideosBack = sceneVideos.slice(i + 1);
                sceneVideosNew =
                    sceneVideosFront.concat(
                        sceneVideosMidFront.concat(
                            sceneVideosMidBack.concat(
                                sceneVideosBack
                            )
                        )
                    );
                break;
            }
        }

        setSceneVideos([...sceneVideosNew]);

        console.log("[CreateScene] shiftLeftVideo sceneVideosNew:", sceneVideosNew);

        // update scene data
        setSceneData(sceneIdOut, JSON.stringify(sceneVideosNew));
    }


    const shiftRightVideo = async (video) => {
        const sceneVideosCount = sceneVideos.length;
        let sceneVideosNew = [];
        for (let i = 0; i < sceneVideosCount; i++) {
            if (sceneVideos[i].videoId === video.videoId) {
                if (i === sceneVideosCount - 1) {
                    break;
                }

                let sceneVideosFront = sceneVideos.slice(0, i);
                let sceneVideosMidFront = sceneVideos.slice(i + 1, i + 2);
                let sceneVideosMidBack = sceneVideos.slice(i, i + 1);
                let sceneVideosBack = sceneVideos.slice(i + 2);
                console.log("i", i);
                console.log("sceneVideos", sceneVideos);
                console.log("sceneVideosFront", sceneVideosFront);
                console.log("sceneVideosMidFront", sceneVideosMidFront);
                console.log("sceneVideosMidBack", sceneVideosMidBack);
                console.log("sceneVideosBack", sceneVideosBack);
                sceneVideosNew =
                    sceneVideosFront.concat(
                        sceneVideosMidFront.concat(
                            sceneVideosMidBack.concat(
                                sceneVideosBack
                            )
                        )
                    );
                break;
            }
        }

        setSceneVideos([...sceneVideosNew]);

        console.log("[CreateScene] shiftRightVideo sceneVideosNew:", sceneVideosNew);

        // update scene data
        setSceneData(sceneIdOut, JSON.stringify(sceneVideosNew));
    }


    // create new scenes

    const showCreateNewScene = () => {
        setIsShowCreateNewScene(true);
    }


    const closeCreateNewScene = () => {
        setIsShowCreateNewScene(false);
    }


    const createNewScene = async () => {
        setIsShowCreateNewScene(false);
        const response = axios.post(
            URL_API_FACESWAP + 'content/add',
            {
                contentId: textFieldSceneId,
                appId: 'faceswapscene',
                content: '[]',
                contentType: 'scene'
            }
        ).then(async (response) => {
            console.log('[CreateScene] createNewScene response:', response);
            if (response.data.r === 's') {
                setSceneVideos([]);
                setSceneId('' + textFieldSceneId);
                setSelectedSceneIndex(-1);
                getScenes();
                console.log('[CreateScene] createNewScene sceneId:', sceneIdOut, 'scenes:', scenes);
            }
        })
        .catch(error => {
            console.log('[CreateScene] createNewScene error:', error);
        });
    }


    // random string

    const getRandomString = (length) => {
        return (new Array(length)).fill(0).map(() => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 1)).join("");
    }


    // render

    return (
        <>
            <div className="header-container">
                <div>
                    <div className="header-button inline"></div>
                    <div className="header-button inline"></div>
                    <div className="header-button inline"></div>
                </div>
                <div className="header-text">Create Scene</div>
                <div>
                    <Link component={RouterLink} to="/video" style={{ textDecoration: 'none' }}>
                        <Button variant="outlined">Video</Button>
                    </Link>
                    <Link component={RouterLink} to="/swap" style={{ textDecoration: 'none' }}>
                        <Button variant="outlined">Swap</Button>
                    </Link>
                    <Link component={RouterLink} to="/queue" style={{ textDecoration: 'none' }}>
                        <Button variant="outlined">Queue</Button>
                    </Link>
                </div>
            </div>


            <div className="previews">
                { sceneVideos.map((video, index) => {
                    return (
                        <div className="scene-preview-container" key={`scenevideo-${index}`}>
                            <div>
                                <video className="scene-preview-video" src={video.url} playsInline controls></video>
                            </div>
                            <div className="scene-preview-picker">
                                { index > 0 ?
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            shiftLeftVideo(video);
                                        }}
                                        size="small"
                                    ><IconArrowLeft/></IconButton>
                                    : null
                                }

                                <span style={{ whiteSpace: 'nowrap'}}>
                                    <Checkbox
                                        checked={video.isSwappable}
                                        color="primary"
                                        onChange={() => {
                                            toggleSwappable(video);
                                        }}
                                        size="small"
                                    />
                                    <IconPeople style={{ verticalAlign: 'middle' }}/>
                                </span> &nbsp;

                                <IconButton
                                    color="secondary"
                                    onClick={() => {
                                        showConfirmRemoveVideoFromScene(video);
                                    }}
                                    size="small"
                                ><IconRemoveCircle/></IconButton>

                                <span style={{ whiteSpace: 'nowrap'}}>
                                    <Radio
                                      checked={thumbnailId === `${video.videoId}`}
                                      onChange={() => {
                                          toggleThumbnail(video);
                                      }}
                                      value={`${video.videoId}`}
                                      name="thumbnail"
                                      size="small"
                                      color="primary"
                                    />
                                    <IconThumbnail style={{ verticalAlign: 'middle' }}/>
                                </span>

                                {
                                    index < sceneVideos.length - 1 ?
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            shiftRightVideo(video);
                                        }}
                                        size="small"
                                    ><IconArrowRight/></IconButton>
                                    : null
                                }
                            </div>
                        </div>
                    );
                })}
            </div>

            {
                sceneId !== '' ?
                (<div style={{ marginLeft: 20 }}>
                    <form noValidate autoComplete="off">
                        <TextField
                            onChange={e => onChangeContentType(e.target.value)}
                            label="contentType"
                            value={contentType}
                        />
                    </form>
                </div>) : null
            }

            <div className="previews">
                <div className="data-list">
                    <div className="data-list-header">scenes</div>

                    <FixedSizeList
                        className="data-list-background"
                        width={'30vw'}
                        height={200}
                        itemSize={30}
                        itemCount={scenes.length}
                    >
                        { renderScenesList }
                    </FixedSizeList>

                    <div>
                        <Button onClick={showCreateNewScene} color="primary">
                          <IconAdd/>
                        </Button>

                        <Button onClick={showConfirmRemoveScene} color="primary">
                          <IconRemove/>
                        </Button>
                    </div>
                </div>

                <div className="upload-container">
                    <FileUploadWithTitle
                        type="video"
                        onUploaded={onUploadedVideo} />
                </div>

                <div className="data-list">
                    <div className="data-list-header">videos</div>
                    <FixedSizeList
                        className="data-list-background"
                        width={'30vw'}
                        height={200}
                        itemSize={30}
                        itemCount={videos.length}
                    >
                        { renderVideosList }
                    </FixedSizeList>

                    <div>
                        <Button onClick={addSelectedVideoToScene} color="primary">
                          <IconAddVideoToScene/>
                        </Button>

                        <Button onClick={showConfirmRemoveVideo} color="primary">
                          <IconRemove/>
                        </Button>
                    </div>
                </div>
            </div>

            <Dialog
              open={isShowConfirmRemoveVideoFromScene}
              onClose={closeConfirmRemoveVideoFromScene}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Remove video?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Remove this video from the scene?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={removeVideoFromScene} color="primary">
                  <IconCheck/>
                </Button>
                <Button onClick={closeConfirmRemoveVideoFromScene} color="primary" autoFocus>
                  <IconClear/>
                </Button>
              </DialogActions>
            </Dialog>


            <Dialog
              open={isShowCreateNewScene}
              onClose={closeCreateNewScene}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Create a new Scene"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Add a title for this scene
                </DialogContentText>
                <form noValidate autoComplete="off">
                    <TextField label="new scene title" onChange={e => setTextFieldSceneId(e.target.value)} />
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={createNewScene} color="primary">
                  <IconCheck/>
                </Button>
                <Button onClick={closeCreateNewScene} color="primary" autoFocus>
                  <IconClear/>
                </Button>
              </DialogActions>
            </Dialog>


            <Dialog
              open={isShowConfirmRemoveScene}
              onClose={closeConfirmRemoveScene}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Remove this Scene?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {sceneId}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={removeScene} color="primary">
                  <IconCheck/>
                </Button>
                <Button onClick={closeConfirmRemoveScene} color="primary" autoFocus>
                  <IconClear/>
                </Button>
              </DialogActions>
            </Dialog>


            <Dialog
              open={isShowConfirmRemoveVideo}
              onClose={closeConfirmRemoveVideo}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Remove this Video?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {selectedVideo.title}
                  <br/>
                  {selectedVideo.url}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={removeVideo} color="primary">
                  <IconCheck/>
                </Button>
                <Button onClick={closeConfirmRemoveVideo} color="primary" autoFocus>
                  <IconClear/>
                </Button>
              </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateScene;
