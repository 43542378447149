// lib
import axios from 'axios';
import { Button, Link, MenuItem, Select } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// content
import "./FaceSwap.css";
import FileUpload from "./FileUpload";
import ProcessSwapsQueue from "./ProcessSwapsQueue";
import ShareScene from "./ShareScene";

const URL_API_FACESWAP = 'https://56mrpvwgbl.execute-api.us-east-1.amazonaws.com/faceswap/';
const SCENE_ID_DEFAULT = 'test';

const FaceSwapQueue = () => {

    const [sceneId, setSceneId] = useState('');
    const [scenes, setScenes] = useState([]);
    const [sceneVideos, setSceneVideos] = useState([]);
    const [resultVideos, setResultVideos] = useState([]);
    const [resultSceneVideoState, setResultSceneVideoState] = useState({});
    const [urlSourceImage, setUrlSourceImage] = useState('');
    const [urlResultSceneVideo, setUrlResultSceneVideo] = useState('');
    const [isProcessSwaps, setIsProcessSwaps] = useState(false);
    const [isShareScene, setIsShareScene] = useState(false);
    const [isUploadImage, setIsUploadImage] = useState(false);


    useEffect(() => {
        getScenes();
    }, []);


    const getScenes = async () => {
        console.log("[FaceSwapQueue] getScenes");
        try {
            const response = await axios.post(
                URL_API_FACESWAP + "contents/get/",
                { "appId": "faceswapscene" }
            );

            console.log("[FaceSwapQueue] getScenes response:", response);

            let data = response.data.data;

            if (data === null || data.length == 0) {
                return;
            }

            await setScenes(data);

        }
        catch(error) {
            console.log("[FaceSwapQueue] getScenes error:", error);
        }
    };


    const onPickScene = (event) => {
        const contentId = event.target.value;
        console.log("[FaceSwapQueue] onPickScene contentId:", contentId)

        setSceneId(contentId);
        renderSelectedScene(contentId);
        setIsUploadImage(true);
    }


    const renderSelectedScene = async (contentId) => {
        console.log("[FaceSwapQueue] setSelectedScene contentId:", contentId)
        onPickAnotherScene();
        const response = await axios.get(
            URL_API_FACESWAP + "content/get/" + contentId,
        );

        console.log("[FaceSwapQueue] renderSelectedScene response:", response);

        if (!response.data.data || !response.data.data.content) {
            return;
        }

        setSceneVideos([...response.data.data.content]);
    };


    const onPickAnotherScene = () => {
        setResultVideos([]);
        setUrlResultSceneVideo('');
        setIsUploadImage(true);
        setIsProcessSwaps(false);
        setIsShareScene(false);
    }


    const onUploadedSourceImage = (url) => {
        setUrlSourceImage(url);
        setIsProcessSwaps(true);
    };


    const onProcessSwapsStarted = (data) => {
        console.log('[FaceSwapQueue] onProcessSwapsStarted data:', data);
        setResultSceneVideoState(data);
    };

    const onProcessSwapsEnded = async (resultSceneVideoStateIn) => {
        console.log('[FaceSwapQueue] onProcessSwapsEnded resultSceneVideoState:', resultSceneVideoState);
        if (resultSceneVideoStateIn.isReady) {
            await setUrlResultSceneVideo(resultSceneVideoStateIn.url);
        }
        await setResultSceneVideoState(resultSceneVideoStateIn);
    };


    return (
        <>
            <div className="header-container">
                <div>
                    <div className="header-button inline"></div>
                    <div className="header-button inline"></div>
                    <div className="header-button inline"></div>
                </div>
                <div className="header-text">Face Swap</div>
                <div>
                    <Link component={RouterLink} to="/create" style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" className="header-button">Create</Button>
                    </Link>
                    <Link component={RouterLink} to="/video" style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" className="header-button">Video</Button>
                    </Link>
                    <Link component={RouterLink} to="/swap" style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" className="header-button">Swap</Button>
                    </Link>
                </div>
            </div>
            <div className="screen-instruction">Pick a scene, then pick a video clip to swap.</div>

            <div className="select-container">
                <Select
                    className="select-list"
                    onChange={onPickScene}
                >
                    {
                        scenes.map((scene, index) => {
                            return (<MenuItem
                                key={`scene-${index}`}
                                value={scene.contentId}
                                >{scene.contentId}</MenuItem>);
                        })
                    }
                </Select>
            </div>

            {
                sceneVideos.length > 0 ? <div className="section-header">scene videos</div> : null
            }

            <div className="previews">
                {
                    sceneVideos.map((video, index) => {
                        return (
                            <div className="scene-preview-container" key={`video-${index}`}>
                                <div>
                                    <video className="scene-preview-video" src={video.url} playsInline controls></video>
                                </div>
                                { video.isSwappable ?
                                    <div className="scene-preview-picker">
                                        swap
                                    </div>
                                    : <div className="scene-preview-picker">&nbsp;</div>
                                }
                            </div>
                            : null
                        );
                    })
                }
            </div>

            <div className="uploads">
                { urlSourceImage !== ''
                    ? <div className="preview-container"><img className="preview-image" src={urlSourceImage} /></div>
                    : null
                }

                { urlResultSceneVideo !== ''
                    ? <div className="preview-container"><video className="preview-video" src={urlResultSceneVideo} playsInline controls></video></div>
                    : null
                }
            </div>

            <div className="uploads">

                { isUploadImage
                    ? <div className="upload-container">
                        <FileUpload
                            type="image"
                            onUploaded={onUploadedSourceImage} />
                    </div>
                    : null
                }

                { isProcessSwaps
                    ? <div className="upload-container">
                        <ProcessSwapsQueue
                            urlSourceImage={urlSourceImage}
                            sceneId={sceneId}
                            onProcessSwapsStarted={onProcessSwapsStarted}
                            onProcessSwapsEnded={onProcessSwapsEnded} />
                    </div>
                    : null
                }

            </div>

            <br/>
        </>
    );
};

export default FaceSwapQueue;
