import React, { useState, useEffect } from 'react';
import './FileUpload.css';
import UploadIcon from '../assets/svg/upload.svg';
import { CircularProgress, TextField } from '@material-ui/core';
import axios from 'axios';
import path from 'path';
import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';

const BUCKET_NAME = 'aiugc';

AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-1:4d34e588-aa87-40f3-88e0-bd24b6af8fae',
    }),
});

const s3 = new S3();


const FileUploadWithTitle = (props) => {

    const [values, setValues] = useState({
        type: '',
        file: '',
        loading: false,
        onUploaded: null
    });


    const [urlUploaded, setUrlUploaded] = useState('');
    const [videoTitle, setVideoTitle] = useState('');


    useEffect(() => {
        setValues({
            ...values,
            type: props.type ? props.type : 'video',
            onUploaded: props.onUploaded
        });
    }, [props]);


    const onChange = (event) => {
        setValues({
            ...values,
            title: event.target.value
        })
    }


    const onFileUpload = (event) => {
        event.preventDefault();
        setValues({ ...values, file: event.target.files[0] });
    };


    const onSubmit = () => {
        if (values.file) {
            setValues({
                ...values,
                loading: true,
            });
            fileAwsHandler(values.file, values.onUploaded);
        }
    };


    // test callback
    const onUploaded = (url) =>  {
        console.log("[FileUpload] url:", url);
    };


    const fileAwsHandler = async (file, callback) => {
        let filename = `${values.type}_${Date.now()}${path.extname(file.name).toLowerCase()}`;

        let params = {
            ACL: 'public-read',
            Bucket: BUCKET_NAME,
            Key: 'uploads/' + filename,
            ContentType: file.type,
            Body: file,
        };

        console.log("[FileUpload] fileAwsHandler filename:", filename, "params:", params);

        s3.putObject(params, function (err, data) {
            if (err) {
                console.log('[FileUpload] fileAwsHandler error:', err);

                setUrlUploaded("");

                setValues({
                    ...values,
                    loading: false,
                });
            } else {
                setValues({
                    ...values,
                    loading: false,
                });

                let url = `https://aiugc.s3.amazonaws.com/uploads/${filename}`;
                setUrlUploaded(url);
                callback(url, videoTitle);
            }
        });
    };


    const fileNameGenerator = (file) => {
        return file.name
    }


    const getFileTypeText = (type) => {
        switch (type) {
            case 'image':
                return '(only jpg / png image)';
            default:
                return '(only mp4 video)';
        }
    }


    const getMimeType = (type) => {
        switch (type) {
            case 'image':
                return 'image/jpeg, image/png';
            default:
                return 'video/mp4';
        }
    }


    return (
        <div className="file-upload">
            {values.loading ? (
                <div className="file-loading">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="file-field">Upload {values.type}</div>
                    <div className="file-upload-area">
                        {
                            values.file ?
                            <>
                                <p>{fileNameGenerator(values.file)}</p>
                                <input type="file" accept="{getMimeTypes(values.type)}" name="file" id="file" onChange={onFileUpload} className="file-input" />
                                <p className="file-link">{urlUploaded}</p>
                            </> :
                            <>
                                <p>Drag and drop or click to upload <br />{getFileTypeText(values.type)}</p>
                                <input type="file" accept="{getMimeTypes(values.type)}" name="file" id="file" onChange={onFileUpload} className="file-input" />
                            </>
                        }

                    </div>

                    <form noValidate autoComplete="off">
                        <TextField label="new video title" onChange={e => setVideoTitle(e.target.value)} />
                    </form>

                    <button onClick={onSubmit}>Upload</button>
                </>
            )}
        </div>
    );
};

export default FileUploadWithTitle;
