import axios from 'axios';
import React, { useState, useEffect } from 'react';

import './ProcessVideo.css';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconDownload from '@material-ui/icons/GetApp';

const BUCKET_NAME = 'aiugc';
const URL_API_AI = 'https://ai.foundrysix.com/api/json/';
const URL_VIDEOS = 'https://aiugc.s3.amazonaws.com/videos/';

const ProcessSwaps = (props) => {

    const [values, setValues] = useState({
        urlSourceImage: '',
        sceneId: '',
        isProcessingSwaps: false,
        onProcessSwapsStarted: null,
        onProcessSwapsEnded: null,
    });

    const [statusProcessSwaps, setStatusProcessSwaps] = useState("");
    const [downloadLink, setDownloadLink] = useState("");
    const [isBlend, setIsBlend] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        setValues({
            ...values,
            urlSourceImage: props.urlSourceImage,
            sceneId: props.sceneId,
            onProcessSwapsStarted: props.onProcessSwapsStarted,
            onProcessSwapsEnded: props.onProcessSwapsEnded
        });
    }, [props]);


    // random string

    const getRandomString = (length) => {
        return (new Array(length)).fill(0).map(() => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 1)).join("");
    }


    // submit

    const onSubmit = () => {
        console.log('[ProcessSwaps] onSubmit urlSourceImage:', values.urlSourceImage, 'sceneId', values.sceneId);
        if (values.urlSourceImage !== '' && values.sceneId !== '') {
            setValues({
                ...values,
                isProcessingSwaps: true,
            });
            processSwaps(values.onProcessSwapsStarted, values.onProcessSwapsEnded);
        }
        else {
            console.log('[ProcessSwaps] missing urlSourceImage or sceneId');
        }
    };


    // process swaps

    const processSwaps = async (onProcessSwapsStarted, onProcessSwapsEnded) => {
        console.log('[ProcessSwaps] processSwaps');

        setDownloadLink('');

        let stateId = getRandomString(8);
        let filenameResultVideo = getRandomString(10) + '.mp4';
        let resultSceneVideoState = { stateId: stateId, url: URL_VIDEOS + filenameResultVideo, isReady: false };
        onProcessSwapsStarted(resultSceneVideoState);

        try {
            const responseSwap = await axios.get(URL_API_AI + 'faceswap/scene?image=' + values.urlSourceImage + '&sceneId=' + encodeURIComponent(values.sceneId) + '&filenameResultVideo=' + filenameResultVideo + '&stateId=' + stateId + (isBlend ? '&isBlend=true' : ''));
            console.log('[ProcessSwaps] responseSwap:', responseSwap);
            setValues({
                ...values,
                isProcessingSwaps: false
            });
            if (responseSwap.data.data && responseSwap.data.data.url) {
                setDownloadLink(responseSwap.data.data.url);
                setStatusProcessSwaps(responseSwap.data.data.url);
                resultSceneVideoState.isReady = true;
                onProcessSwapsEnded(resultSceneVideoState);
            }
            else {
                setDownloadLink('');
                setStatusProcessSwaps('error')
                onProcessSwapsEnded(resultSceneVideoState);
            }
        }
        catch (error) {
            console.log('[ProcessSwaps] processSwaps', error);
            setValues({
                ...values,
                isProcessingSwaps: false
            });
            setDownloadLink('');
            setStatusProcessSwaps('error')
            onProcessSwapsEnded(resultSceneVideoState);
        }
    };


    const download = (url) => {
        if (!url) {
          throw new Error("Resource URL not provided! You need to provide one");
        }

        setIsDownloading(true);

        fetch(url)
        .then(response => response.blob())
        .then(blob => {
          setIsDownloading(false);
          const blobURL = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobURL;
          link.style = "display: none";
          const parts = url.split("/");
          link.download = parts[parts.length - 1];
          document.body.appendChild(link);
          setTimeout(function() {
               link.click();
               document.body.removeChild(link);
           }, 500);
        })
        .catch(() => {
          setIsDownloading(false);
          console.log("download error");
        });
    };


    const toggleBlend = () => {
        setIsBlend(!isBlend);
    };


    // render

    return (
        <div className="process-video">
            {values.isProcessingSwaps ? (
                <div className="process-isLoading">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="process-field">Face swap image to videos</div>
                    <div className="process-info">
                    <div>
                        <Checkbox
                            checked={isBlend}
                            color="primary"
                            onChange={() => {
                                toggleBlend(isBlend);
                            }}
                            size="small"
                        /> blend
                    </div>
                    { downloadLink !== ""
                        ? <div>
                            <button
                                disabled={isDownloading}
                                onClick={()=> download(downloadLink)}
                            >
                                <IconDownload/>
                            </button>
                        </div>
                        : null
                    }
                    <p className="process-link">{statusProcessSwaps}</p>
                    </div>

                    <button onClick={onSubmit}>Face Swap</button>
                </>
            )}
        </div>
    );
};

export default ProcessSwaps;
