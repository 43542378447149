import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import CreateScene from './components/CreateScene.js';
import FaceSwap from './components/FaceSwap.js';
import FaceSwapQueue from './components/FaceSwapQueue.js';
import FaceSwapVideo from './components/FaceSwapVideo.js';

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <FaceSwapVideo />
                </Route>
                <Route path="/swap" exact>
                    <FaceSwap />
                </Route>
                <Route path="/create" exact>
                    <CreateScene />
                </Route>
                <Route path="/queue" exact>
                    <FaceSwapQueue />
                </Route>
                <Route path="/video" exact>
                    <FaceSwapVideo />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
