// lib
import { Button, Link } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// content
import "./FaceSwapVideo.css"
import FileUpload from "./FileUpload"
import ProcessVideo from "./ProcessVideo"

const FaceSwapVideo = () => {

    const [urlSourceImage, setUrlSourceImage] = useState("");
    const [urlTargetVideo, setUrlTargetVideo] = useState("");
    const [urlResultVideo, setUrlResultVideo] = useState("");


    const onUploadedSourceImage = (url) => {
        setUrlSourceImage(url);
    };


    const onUploadedTargetVideo = (url) => {
        setUrlTargetVideo(url);
    };


    const onProcessVideo = (url) => {
        setUrlResultVideo(url);
    };


    return (
        <>
            <div className="header-container">
                <div>
                    <div className="header-button inline"></div>
                    <div className="header-button inline"></div>
                    <div className="header-button inline"></div>
                </div>
                <div className="header-text">Face Swap Video</div>
                <div>
                    <Link component={RouterLink} to="/create" style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" className="header-button">Create</Button>
                    </Link>
                    <Link component={RouterLink} to="/swap" style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" className="header-button">Swap</Button>
                    </Link>
                    <Link component={RouterLink} to="/queue" style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" className="header-button">Queue</Button>
                    </Link>
                </div>
            </div>
            <div className="previews">
                { urlSourceImage !== ""
                    ? <div className="preview-container"><img className="preview-image" src={urlSourceImage} /></div>
                    : <div className="preview-container"></div> }
                { urlTargetVideo !== ""
                    ? <div className="preview-container"><video className="preview-video" src={urlTargetVideo} playsInline controls></video></div>
                    : <div className="preview-container"></div> }
                { urlResultVideo !== ""
                    ? <div className="preview-container"><video className="preview-video" src={urlResultVideo} playsInline controls></video></div>
                    : <div className="preview-container"></div> }
            </div>

            <div className="uploads">
                <div className="upload-container">
                    <FileUpload type="image" onUploaded={onUploadedSourceImage}></FileUpload>
                </div>
                <div className="upload-container">
                    <FileUpload type="video" onUploaded={onUploadedTargetVideo}></FileUpload>
                </div>
                { urlSourceImage !== "" && urlTargetVideo !== ""
                    ? <div className="upload-container">
                        <ProcessVideo urlSourceImage={urlSourceImage} urlTargetVideo={urlTargetVideo} onProcessVideo={onProcessVideo}></ProcessVideo>
                    </div>
                    : <div className="upload-container"></div> }
            </div>
        </>
    );
};

export default FaceSwapVideo;
